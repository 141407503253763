import React from "react";
import Choose1 from "../../Assets/Home/Choose2.png";
import Choose2 from "../../Assets/Home/Choose1.jpg";
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import RiddleImage from '../../Assets/img/riddle-game.jpeg';
import MageImage from '../../Assets/img/mageGame.webp';
import CardActionArea from '@mui/material/CardActionArea';

function InnerBattle({ setActiveComponent }) {
    const candidate = useSelector((state) => state.user.selectCryptoTraditional);
    return (
        <div className="flex flex-col w-full">
            <div className="basis-1/12 flex flex-row items-center justify-start">
                <IconButton size="large" onClick={() => setActiveComponent(0)}>
                    <ArrowBackIcon fontSize="inherit" />
                </IconButton>
                <h5 className="w-full font-bold text-center text-xl">Crypto vs traditional finance</h5>
            </div>
            <div className="basis-1/4 flex flex-col justify-center items-center">
                <img
                    src={candidate.id === 1 ? Choose2 : Choose1}
                    alt="Chosen Candidate"
                    className="my-5 block w-[122px] h-[121px] rounded-full border-4 border-solid"
                />
                <p className="font-bold text-[14px] w-full max-w-[282px] mb-5 text-center">{`You've Chosen ${candidate.name}`}</p>
            </div>
            <div className="basis-1/15 flex flex-col">
                <p className="font-bold text-lg">
                    Games
                </p>
            </div>
            <div className="basis-1/15 flex flex-col">
                <p className="text-base">
                    Play to earn more coins in your selected battlefield Play to earn more coins in your selected battlefieldPlay to earn more coins in your selected battlefield
                </p>
            </div>
            <div className="basis-auto mt-2">
            <Card
                key={Math.random()}
                className="flex"
                sx={{
                    boxShadow: 20, // Increase this value for a stronger shadow
                    transition: '0.2s',
                }}>
                <CardActionArea onClick={() => setActiveComponent(4)}>
                    <CardMedia
                        component="img"
                        height="194"
                        image={RiddleImage}
                        alt="Paella dish"
                    />
                    <CardContent>
                        <p className="font-bold text-lg">Riddle</p>
                    </CardContent>
                </CardActionArea>
            </Card>
            </div>
            <div className="basis-auto mt-2">
            <Card
                key={Math.random()}
                className="flex"
                sx={{
                    boxShadow: 20, // Increase this value for a stronger shadow
                    transition: '0.2s',
                }}>
                <CardActionArea onClick={() => setActiveComponent(11)}>
                    <CardMedia
                        component="img"
                        height="194"
                        image={MageImage}
                        alt="Paella dish"
                    />
                    <CardContent>
                        <p className="font-bold text-lg">Maze</p>
                    </CardContent>
                </CardActionArea>
            </Card>
            </div>
        </div>
    );
}

export default InnerBattle;
