import React from "react";
import Tour from "../../Components/Tour/index";
import HomeCard from "./HomeCard";
import { useDispatch, useSelector } from 'react-redux';
import { setTour } from "../../reducer";


function Home() {
  const dispatch = useDispatch();
  const [skip, setSkip] = React.useState(false);
  React.useEffect(() => {
    if (skip) {
      localStorage.setItem("tour", "true")
      dispatch(setTour(true))
    }
  }, [skip])

  React.useEffect(() => {
    if (localStorage.getItem("tour") === "true") {
      setSkip(true);
      dispatch(setTour(true))
    }
  }, [])
  return (
    <div className="flex basis-full min-h-[88dvh]">{!skip && <Tour setSkip={() => setSkip(!skip)} />}
      {skip && <HomeCard />}</div>
  );
}

export default Home;
