export const mazeData = [
    {
        level: 1,
        entryCost: 100,
        wincose: 500,
        gameId: 1,
        questions: [5,6,7,8,9,10,11,12,13,14]
    },
    {
        level: 2,
        entryCost: 200,
        wincose: 1000,
        gameId: 5,
        questions: [15,16,17,18,19,20,21,22,23,24]
    },
    {
        level: 3,
        entryCost: 300,
        wincose: 1500,
        gameId: 10,
        questions: [25,26,27,28,29,30,31,32,33,34]
    }
];