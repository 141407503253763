import { useState, useMemo, useEffect } from "react";
import { generateMaze, solve } from "./utils";
import { ArrowUpward, ArrowDownward, ArrowBack, ArrowForward } from "@mui/icons-material";
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { mazeData } from "../../MazeGameFile/index";
import Card from '@mui/material/Card';
import { updateUser } from "../../api";
import { setUser } from "../../reducer";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import "./index.scss";

export default function QuizeGame({ setActiveComponent }) {
    const userInfo = useSelector((state) => state.user.userInfo);
    const dispach = useDispatch();
    const userInfoRiddle = useSelector((state) => state.user.userInfo.mazeGameGame);
    const [currentLevel, setCurrentLevel] = useState(userInfoRiddle.level);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(userInfoRiddle.levelDone === undefined ? 0 : userInfoRiddle.levelDone);
    // const [userAnswer, setUserAnswer] = useState('');
    // const [nextLevelState, setNextLevelState] = useState(false);
    // const [inputFieldValue, setInputFieldValue] = useState("");
    const currentLevelData = mazeData.find(level => level.level === (currentLevel === undefined ? 1 : currentLevel));
    const currentQuestion = currentLevelData.questions[currentQuestionIndex === undefined ? 0 : currentQuestionIndex];
    const [gameId, setGameId] = useState(currentLevelData.gameId);
    const [status, setStatus] = useState("playing");
    const [size, setSize] = useState(currentQuestion);


    const [userPosition, setUserPosition] = useState([0, 0]);

    const maze = useMemo(() => generateMaze(size, size), [size, gameId]);
    const solution = useMemo(() => {
        const s = new Set();
        const solutionPath = solve(maze, userPosition[0], userPosition[1]);
        solutionPath.forEach((path) => {
            const [x, y] = path;
            s.add(String(x) + "-" + String(y));
        });
        return s;
    }, [size, userPosition[0], userPosition[1], gameId]);

    useEffect(() => {
        const lastRowIndex = maze.length - 1;
        const lastColIndex = maze[0].length - 1;
        if (userPosition[0] === lastRowIndex && userPosition[1] === lastColIndex) {
            setStatus("won");
            toast.success('You won!!! 🎉');
        }
    }, [userPosition[0], userPosition[1]]);

    const makeClassName = (i, j) => {
        const rows = maze.length;
        const cols = maze[0].length;
        let arr = [];
        if (maze[i][j][0] === 0) {
            arr.push("topWall");
        }
        if (maze[i][j][1] === 0) {
            arr.push("rightWall");
        }
        if (maze[i][j][2] === 0) {
            arr.push("bottomWall");
        }
        if (maze[i][j][3] === 0) {
            arr.push("leftWall");
        }
        if (i === rows - 1 && j === cols - 1) {
            arr.push("destination");
        }
        if (i === userPosition[0] && j === userPosition[1]) {
            arr.push("currentPosition");
        }

        // if (cheatMode && solution.has(String(i) + "-" + String(j))) {
        //     arr.push("sol");
        // }
        if (i === userPosition[0] && j === userPosition[1]) {
            arr.push("currentPosition", "animate-bounce", "hover:animate-shake");
        }
        return arr.join(" ");
    };

    const movePlayer = (dx, dy, key) => {
        const newX = userPosition[0] + dx;
        const newY = userPosition[1] + dy;
        const [i, j] = userPosition;
        if (status === "playing") {
            if (key === "up" && maze[i][j][0] === 1) {
                setUserPosition([newX, newY]);
            }
            if (key === "down" && maze[i][j][2] === 1) {
                setUserPosition([newX, newY]);
            }
            if (key === "left" && maze[i][j][3] === 1) {
                setUserPosition([newX, newY]);
            }
            if (key === "right" && maze[i][j][1] === 1) {
                setUserPosition([newX, newY]);
            }
        }

    };

    const checkAnswer = () => {
        setCurrentQuestionIndex(currentQuestionIndex + 1)
        const currentQuestionUpdated = currentLevelData.questions[currentQuestionIndex === undefined ? 0 : currentQuestionIndex + 1];
        setSize(currentQuestionUpdated);
        setStatus("playing");
        setUserPosition([0, 0]);
        const updateUserInfo = { ...userInfo, balance: userInfo.balance + 500, mazeGameGame: { ...userInfo.mazeGameGame, levelDone: currentQuestionIndex + 1 } };
        updateUser(updateUserInfo);
        dispach(setUser(updateUserInfo))
    }

    const nextLevel = () => {
        if (currentQuestionIndex + 1 === 10) {
            const updateUserInfo = { ...userInfo, mazeGameGame: { ...userInfoRiddle, level: userInfo.mazeGameGame.level + 1, levelDone: 0 } }
            updateUser(updateUserInfo)
            dispach(setUser(updateUserInfo))
            setActiveComponent(13)
        }
    };

    return (
        <div className="App" tabIndex={-1}>
            <div>
                <h2 className="text-lg font-bold mb-2 text-center">Maze Game</h2>
                <p className="text-sm text-gray-600 mb-4 text-center">Level {currentLevel}</p>
                <p className="text-sm text-gray-600 mb-4 text-center">Question {currentQuestionIndex + 1}/10</p>
            </div>
            <Card className="p-4 !overflow-auto">
                <table id="maze">
                    <tbody>
                        {maze.map((row, i) => (
                            <tr key={`row-${i}`}>
                                {row.map((cell, j) => (
                                    <td key={`cell-${i}-${j}`} className={makeClassName(i, j)}>
                                        <div />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card>
            <Card className="mt-4 flex flex-col justify-center space-x-4 p-4">
                <Typography variant="h6" component="div">
                    Use these buttons to move ball
                </Typography>
                <div>
                    <IconButton
                        className="p-3"
                        onClick={() => movePlayer(-1, 0, "up")}
                    >
                        <ArrowUpward fontSize="large" />
                    </IconButton>
                    <IconButton
                        className="p-3"
                        onClick={() => movePlayer(1, 0, "down")}
                    >
                        <ArrowDownward fontSize="large" />
                    </IconButton>
                    <IconButton
                        className="p-3"
                        onClick={() => movePlayer(0, -1, "left")}
                    >
                        <ArrowBack fontSize="large" />
                    </IconButton>
                    <IconButton
                        className="p-3"
                        onClick={() => movePlayer(0, 1, "right")}
                    >
                        <ArrowForward fontSize="large" />
                    </IconButton>
                </div>
            </Card>
            {status === "won" && currentQuestionIndex < 9 && <Button variant="contained"
                onClick={checkAnswer}
                fullWidth
                sx={{
                    backgroundColor: '#87CEEB', // light sky blue color
                    color: 'white',
                    borderRadius: '20px',
                    marginTop: '5%',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: '#6495ED', // slightly darker shade on hover
                    },
                }}>
                Go to Next Maze
            </Button>}
            {status === "won" && currentQuestionIndex === 9 && (
                <Button variant="contained"
                    onClick={nextLevel}
                    fullWidth
                    sx={{
                        backgroundColor: '#87CEEB', // light sky blue color
                        color: 'white',
                        borderRadius: '20px',
                        marginTop: '5%',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#6495ED', // slightly darker shade on hover
                        },
                    }}>
                    Next Level
                </Button>
            )}
        </div>
    );
}
