import React from "react";
import congo from "../../Assets/img/congo.png";
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from "../../api";
import { setUser } from "../../reducer";
import Button from '@mui/material/Button';
import { quizData } from '../../TextGameFile/index';


function MazeWin({ setActiveComponent }) {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.user.userInfo);
    const handleOkayButton = () => {
        if (userInfo.mazeGameGame.level === 3 && userInfo.mazeGameGame.levelDone === 10) {
            setActiveComponent(11)
        } else {
            setActiveComponent(12)
        }
    }
    return (
        <div className="p-5 flex basis-full w-full justify-center items-center">
            <div className="text-center flex flex-col items-center">
                <p className="w-full max-w-[314px] mb-5">Congratulations</p>
                <img
                    src={congo}
                    alt="Chosen Candidate"
                    className="my-5 block w-[122px] h-[121px] rounded-full border-4 border-solid"
                />
                <p className="w-full max-w-[186px] mb-5 text-nowrap">{`You earned total ${quizData.find(key => key.level === userInfo.mazeGameGame.level).wincose * 10} coins`}</p>
                {userInfo.mazeGameGame.level === 4 && userInfo.mazeGameGame.levelDone === 10 && <p className="w-full max-w-[186px] mb-5 text-nowrap">{`All levels are completed`}</p>}
                <Button variant="contained"
                    onClick={() => { handleOkayButton() }}
                    fullWidth
                    sx={{
                        backgroundColor: '#87CEEB', // light sky blue color
                        color: 'white',
                        borderRadius: '20px',
                        marginTop: '5%',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#6495ED', // slightly darker shade on hover
                        },
                    }}>
                    PLAY AGAIN
                </Button>
                <div className="pt-[5%]">
                    <Button onClick={() => setActiveComponent(11)} fullWidth variant="text">Back to level</Button>
                </div>
            </div>
        </div>
    );
}

export default MazeWin;
